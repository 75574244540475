<template>
  <div>
    <div v-if="tabledata" class="banner_data">
      <p v-for="(item, index) in tabledata.tbCardListModelList" :key="index">
        <el-image @click="getdetail(item)" :src="item.cardImage">
          <div slot="placeholder" class="image-holder"></div>
        </el-image>
      </p>
    </div>
    <div v-else>暂无数据</div>
  </div>
</template>
<script>
import {
  getalentList,
  getMobileformula,
  getMobilenio_u,
  getMobileAutumnRecurit,
  getMobilePublicity,
} from "@/api/commonApi";
import mobileBanner from "./homeComponents/mobileBanner.vue";

export default {
  components: {
    mobileBanner,
  },
  data() {
    return {
      tabledata: null,
      path: this.$route.path,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.path === "/mobile/talent") {
        getalentList().then((res) => {
          if (res) this.tabledata = res[0].data[0];
        });
      } else if (this.path === "/mobile/recruit") {
        getMobileformula().then((res) => {
          if (res) this.tabledata = res[0].data[0];
        });
      } else if (this.path === "/mobile/nio_u") {
        getMobilenio_u().then((res) => {
          if (res) this.tabledata = res[0].data[0];
        });
      } else if (this.path === "/mobile/autumnRecruit") {
        getMobileAutumnRecurit().then((res) => {
          if (res) this.tabledata = res[0].data[0];
        });
      } else if (this.path === "/mobile/shine") {
        this.tabledata = {
          id: "191",
          parentId: "0",
          cardTitle: "Shine校园招募计划",
          cardContent: null,
          cardImage: null,
          cardIndex: 0,
          linkUrl: null,
          cardType: "mobileShine",
          cardStarted: 1,
          deleted: 0,
          detailsUrl: null,
          cardVideo: null,
          updateTime: null,
          empty: false,
          tbCardListModelList: [
            {
              id: "192",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list192.jpeg",
              cardIndex: 1,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
            {
              id: "193",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list193.jpeg",
              cardIndex: 2,
              linkUrl:
                "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7107135604417464612&type=&job_hot_flag=&current=1&limit=10&functionCategory=&spread=SAYV33H",
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
            },
            {
              id: "194",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list194.jpeg",
              cardIndex: 3,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
            {
              id: "195",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list195.jpeg",
              cardIndex: 4,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
            {
              id: "196",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list196.jpeg",
              cardIndex: 5,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
            {
              id: "197",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list197.jpeg",
              cardIndex: 6,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
            {
              id: "198",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/18/tb_card_list198.jpeg",
              cardIndex: 7,
              linkUrl:
                "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&type=&job_hot_flag=&current=1&limit=10&functionCategory=&project=7099777680229091598",
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
            },
            {
              id: "199",
              parentId: "191",
              cardTitle: null,
              cardContent: null,
              cardImage:
                "https://cdn-campus.nio.com/nio-campus-service/2022/08/19/tb_card_list199_1.jpeg",
              cardIndex: 9,
              cardType: "mobileShine",
              cardStarted: 1,
              deleted: 0,
              detailsUrl: null,
              cardVideo: null,
              updateTime: null,
              empty: false,
              tbCardListModelList: [],
              createTime: null,
              linkUrl: null,
            },
          ],
          createTime: null,
        };
      } else if (this.path === "/mobile/publicity") {
        getMobilePublicity().then((res) => {
          if (res) this.tabledata = res[0].data[0];
        });
      }
    },
    getdetail(val) {
      if (val.linkUrl) {
        window.open(val.linkUrl, "_blank");
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped lang="less">
.applist {
  width: 100%;
  max-width: 750px;
  margin: 0px auto;

  .banner_data {
    display: flex;
    flex-wrap: wrap;
    margin: 0px auto;

    h1 {
      font-size: 28px;
      font-weight: 800;
      color: #040b29;
      margin-top: 54px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    p {
      margin: 0px;
      padding: 0px;
      text-align: center;
      font-size: 0px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .el-image {
        height: auto;
        width: 100%;

        /deep/ img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-holder {
          height: 100%;
          background: rgba(156, 198, 207, 0.39) url("~@/assets/img/image-holder.png") center no-repeat;
          background-size: 82px 82px;
        }
      }
    }
  }
}

.father1 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}

.father2 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}

.mobile .banner_data p .el-image[data-v-38a35f85] {
  height: 100%;
  width: 100%;
  font-size: 0px;
}

.father1::-webkit-scrollbar {
  display: none;
}

.overflow {
  overflow-x: scroll;
}

.father1 .d1 {
  width: 100%;
  background-color: bisque;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.father1 .d2 {
  width: 100%;
  height: 355px;
  background-color: red;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
